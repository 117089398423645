import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import './i18n'; // Import the i18n configuration
import { openPageViewModal } from './PageViewModalUtils'; // Utility to open the modal
import { Asset } from './types'; // Import the Asset type

// Define the NewsItem interface
interface NewsItem {
  id: string;
  talent_uppercase: string;
  talent_job: string;
  date_update: string;
  date_news: string;
  src: string;
  news: string; // JSON string containing different language versions
  link: string;
  profile_pic: string;
}

// Talent interface for fetching talent details
interface Talent {
  token_address: string;
  token_id: string;
  name: string;
  owner: string;
  date_update: string;
  image_url: string;
  card_id: string;
  token_new_url?: string;
  card_experience?: number;
  card_force?: string;
  ETH_value?: string;
  EUR_value?: string;
  USD_value?: string;
  lineup?: number;
  total_lineup?: number;
  dollar_max?: number;
  euro_max?: number;
  eth_max?: number;
  buyer_max?: string;
  offers_count?: number;
  desired_eth?: number;
  desired_dollar?: number;
  desired_euro?: number;
  current_order?: number;
  pending?: number;
  toTransfer?: number;
  USD_ratio?: string;
  EUR_ratio?: string;
  ETH_ratio?: string;
  profile_new_url?: string;
  similar_cards_total?: number;
  similar_cards_on_sale?: number;
  similar_cards_total_pending?: number;
  lineup_per_talent?: number;
  available_cards?: number;
  potential_buyers?: string;
  total_pending?: number;
  country?: string;
  state?: string;
  city?: string;
  main_nationality?: string;
  main_activity?: string;
  main_category?: string;
  league?: string;
  league_url?: string;
  club?: string;
  club_url?: string;
  national_team?: string;
  national_team_url?: string;
  tag1?: string;
  tag2?: string;
  tag3?: string;
  tag4?: string;
  tag5?: string;
  prize1?: string;
  prize2?: string;
  prize3?: string;
  prize4?: string;
  prize5?: string;
  talent_age?: string;
  talent_name_original?: string;
  talent_first_name?: string;
  talent_last_name?: string;
  talent_instagram?: string;
  talent_youTube?: string;
  talent_facebook?: string;
  talent_tiktok?: string;
  talent_X?: string; // For X (formerly Twitter)
  talent_pinterest?: string;
  talent_linkedIn?: string;
  talent_twitch?: string;
  talent_website?: string;
  talent_force?: string;
  talent_description?: string;
  instagram_followers?: string;
  talent_birthdate?: string;
  talent_experience?: string;
  json_data?: JSON;
  current_score?: string;
  current_game?: string;
  card_xp_bonus?: string;
  card_force_bonus?: string;
  similar_cards_bonus?: string;
  final_score?: string;
  total_score?: string;
}

// Props for NewsStatus component
interface NewsStatusProps {
  onNewsCountChange: (count: number) => void;
  talentUppercase: string;
  limit?: number;
}

// Props for LastNewsDisplay component
interface LastNewsDisplayProps {
  talentUppercase?: string | null;
  limit?: number;
  modal?: string;
  onNewsCountChange: (count: number) => void;
}

// NewsStatus Component: Fetches the count of news for the talent
export const NewsStatus: React.FC<NewsStatusProps> = ({ onNewsCountChange, talentUppercase, limit = 5 }) => {
  const [newsCount, setNewsCount] = useState<number | null>(null);

  useEffect(() => {
    const fetchNewsCount = async () => {
      const currentHostname = window.location.href;
      const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
      const linkUrl = currentHostname.includes(`${stagingKey}`)
        ? `https://${stagingKey}.peaxel.me`
        : 'https://peaxel.me';

      try {
        const response = await axios.get(`${linkUrl}/wp-json/custom/v1/last-news?talent_uppercase=${talentUppercase}&limit=${limit}`);
        const news = response.data as NewsItem[];

        setNewsCount(news.length);
        onNewsCountChange(news.length); // Trigger the callback to pass the count up
      } catch (error) {
        setNewsCount(0);
        onNewsCountChange(0); // No news or an error occurred
      }
    };

    fetchNewsCount();
  }, [talentUppercase, limit, onNewsCountChange]);

  return null; // This component doesn't render anything visually
};

// LastNewsDisplay Component: Displays news items
export const LastNewsDisplay: React.FC<LastNewsDisplayProps> = ({
  talentUppercase,
  limit = 20,
  modal = '',
  onNewsCountChange,
}) => {
  const { t } = useTranslation();
  const [news, setNews] = useState<NewsItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [offset, setOffset] = useState(0);
  const [allLoaded, setAllLoaded] = useState(false);
  const [talentDetails, setTalentDetails] = useState<{ [key: string]: Talent | null }>({});

  const isMobile = window.innerWidth < 768;
  const currentHostname = window.location.href;
  const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
  const linkUrl = currentHostname.includes(`${stagingKey}`)
    ? `https://${stagingKey}.peaxel.me`
    : 'https://peaxel.me';

  const language = localStorage.getItem('pxlLanguage') || 'en';

  // Function to fetch the news items
  const fetchLastNews = async (newOffset: number) => {
    try {
      const apiUrl = talentUppercase
        ? `${linkUrl}/wp-json/custom/v1/last-news?talent_uppercase=${talentUppercase}&offset=${newOffset}&limit=${limit}`
        : `${linkUrl}/wp-json/custom/v1/last-news?offset=${newOffset}&limit=${limit}`;

      const response = await axios.get<NewsItem[]>(apiUrl);

      if (response.data.length < limit) {
        setAllLoaded(true); // If fewer items are returned than the limit, all news is loaded
      }

      setNews((prevNews) => [...prevNews, ...response.data]); // Append new items to the existing list
      onNewsCountChange(response.data.length); // Notify the parent component about the news count
      setLoading(false);
    } catch (err) {
      setError('Error fetching news');
      onNewsCountChange(0); // Notify the parent that there's an error or no news
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLastNews(0); // Initial fetch
  }, [talentUppercase, limit]);

// Function to fetch talent details based on talent name
const fetchTalentDetails = async (talentName: string) => {
    if (!talentDetails[talentName]) {
      try {
        const response = await axios.get<Talent[]>(
          `${linkUrl}/wp-json/custom/v1/custom-search?searchterm=${talentName.toLowerCase()}`
        );
        setTalentDetails((prevState) => ({
          ...prevState,
          [talentName]: response.data[0] || null, // Store first talent result, or null if not found
        }));
      } catch (err) {
        console.error(`Error fetching talent data for ${talentName}:`, err);
      }
    }
  };

  // Create an Asset from the fetched talent data to open in the modal
  const createAssetFromTalent = (talent: Talent) => {
    return {
        
            collection: {
              icon_url: '', // Using the profile picture as a fallback icon
              name: 'PEAXEL_INTRO_BIS', // Placeholder for collection name
            },
            created_at: new Date().toISOString(),
            description: talent.talent_description || '', // Description based on job and country
            id: talent.card_id,
            image_url: talent.image_url, // Using the profile picture as the main image
            metadata: {
              country: talent.country,
              description: talent.talent_description || '',
              name: talent.name,
              image_url: talent.token_new_url || '', // Reusing profile picture in metadata
            },
            name: talent.name,
            status: 'active', // Assuming a default active status
            token_address: talent.token_address, // Placeholder token address
            token_id: talent.token_id, // Placeholder token ID
            updated_at: new Date().toISOString(),
            uri: null,
            user: '', // Placeholder user
            talentCard: {
              token_address: talent.token_address,
              token_id: talent.token_id,
              token_new_url: talent.token_new_url || '', //
              card_id: talent.card_id,
              name: talent.name,
              owner: talent.owner,
              date_update: new Date().toISOString(),
              image_url: talent.token_new_url || '',
              card_experience: talent.card_experience || 0,
              card_force: talent.card_force || '',
              ETH_value: talent.ETH_value || '',
              EUR_value: talent.EUR_value || '',
              USD_value: talent.USD_value || '',
              lineup: 1,
              ETH_ratio: '',
              EUR_ratio: '',
              USD_ratio: '',
              buyer_max: '',
              current_order: '',
              desired_dollar: '',
              desired_eth: '',
              desired_euro: '',
              dollar_max: '',
              eth_max: '',
              euro_max: '',
              offers_count: '',
              pending: '',
              toTransfer: '',
              total_lineup: '',
              profile_new_url: talent.profile_new_url || '',
              similar_cards_total: 0,
              similar_cards_on_sale: 0,
              similar_cards_total_pending: 0,
              lineup_per_talent: 0,
              available_cards: 0,
              potential_buyers: '',
              total_pending: 0,
              country: talent.main_nationality || '',
              state: talent.state || '',
              city: talent.city || '',
              main_nationality: talent.main_nationality || '',
              main_activity: talent.main_activity || '',
              main_category: talent.main_category || '',
              league: talent.league || '',
              league_url: talent.league_url || '',
              club: talent.club || '',
              club_url: talent.club_url || '',
              national_team: talent.national_team || '',
              national_team_url: talent.national_team_url || '',
              tag1: talent.tag1 || '',
              tag2: talent.tag2 || '',
              tag3: talent.tag3 || '',
              tag4: talent.tag4 || '',
              tag5: talent.tag5 || '',
              prize1: talent.prize1 || '',
              prize2: talent.prize2 || '',
              prize3: talent.prize3 || '',
              prize4: talent.prize4 || '',
              prize5: talent.prize5 || '',
              talent_age: talent.talent_age || '',
              talent_name_original: talent.talent_name_original || '',
              talent_first_name: talent.talent_first_name || '',
              talent_last_name: talent.talent_last_name || '',
              talent_instagram: talent.talent_instagram || '',
              talent_youTube: talent.talent_youTube || '',
              talent_facebook: talent.talent_facebook || '',
              talent_tiktok: talent.talent_tiktok || '',
              talent_X: talent.talent_X || '',
              talent_pinterest: talent.talent_pinterest || '',
              talent_linkedIn: talent.talent_linkedIn || '',
              talent_twitch: talent.talent_twitch || '',
              talent_website: talent.talent_website || '',
              talent_force: talent.talent_force || '',
              talent_description: talent.talent_description || '',
              instagram_followers: talent.instagram_followers || '',
              talent_birthdate: talent.talent_birthdate || '',
              talent_experience: talent.talent_experience || '',
              json_data: talent.json_data || {},
              current_score: talent.current_score || '',
              current_game: talent.current_game || '',
              card_xp_bonus: talent.card_xp_bonus || '',
              card_force_bonus: talent.card_force_bonus || '',
              similar_cards_bonus: talent.similar_cards_bonus || '',
              final_score: talent.final_score || '',
              total_score: talent.total_score || '',
          }
    };
  };

  // Open the talent profile in a modal when clicking the talent name or picture
  const handleProfileClick = useCallback(
    async (talentName: string) => {
      // Check if the talent details are already available in state
      let talent = talentDetails[talentName];
  
      // If talent details are not already available, fetch them
      if (!talent) {
        await fetchTalentDetails(talentName); // Fetch the talent details
        talent = talentDetails[talentName]; // Retrieve the fetched details from state
      }
  
        if (talent) {
        const asset = createAssetFromTalent(talent);
        openPageViewModal(asset, ''); // Open modal with the fetched talent data
        }
      
    },
    [talentDetails] // Dependencies ensure hook is called properly
  );
  





  // Load more news when the user clicks
  const loadMore = () => {
    const newOffset = offset + limit;
    setOffset(newOffset);
    fetchLastNews(newOffset);
  };

  // Loading state
  if (loading && news.length === 0) {
    return <div>{t('loading')}</div>;
  }

  // Error state
  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className={modal === 'profileViewModal' ? 'last-news-container-profile' : 'last-news-container'}>
      {news.length > 0 ? (
        <div className={modal === 'profileViewModal' ? 'last-news-list-profile' : 'last-news-list'}>
          {news.map((item) => {
            // Parse the 'news' field, which is stored as JSON
            const newsTranslations = JSON.parse(item.news);
            const newsInLanguage = newsTranslations[language] || newsTranslations['en'] || 'No news available';

            return (
              <div key={item.id} className="sale-item-news" style={{ fontSize: '125%' }}>
                <div className={modal === 'profileViewModal' ? 'sale-details-news-profile' : 'sale-details-news'}>
                  <span className="sale-details-container-news">
                    <div className={modal === 'profileViewModal' ? 'sale-image-news-profile' : 'sale-image-news'}>
                      <img src={item.profile_pic} alt={item.talent_uppercase}
                      onClick={() => {
                        if (modal === '') {
                          handleProfileClick(item.talent_uppercase);
                        }}}
                      />
                    </div>
                    <span className={modal === 'profileViewModal' ? 'sale-details-header-profile' : 'sale-details-header'}>
                      <span className="sale-token-news">{newsInLanguage}</span>
                      <span className="sale-date-news" style={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (modal === '') {
                          handleProfileClick(item.talent_uppercase);
                        }}}>
                        {item.date_news} - {item.talent_uppercase} - {item.talent_job}
                      </span>
                    </span>
                  </span>
                  <span className="sale-price-news">
                    <span className="sale-price-currency" style={{ color: '#93003F', fontWeight: 'bold' }}>
                      <a
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          color: '#93003F',
                          fontWeight: 'bold',
                          textDecoration: 'none',
                        }}
                      >
                        {t('learnMore')}
                      </a>
                    </span>
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <p>{t('noNewsAvailable')}</p>
      )}

      {/* Load more button */}
      {!allLoaded && news.length > 0 && (
        <div className="load-more-container-news">
          <span className={modal === 'profileViewModal' ? 'load-more-news-profile' : 'load-more-news'} onClick={loadMore}>
            {t('loadMoreNews')}
          </span>
        </div>
      )}
    </div>
  );
};

export default LastNewsDisplay;
